// RESETS

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
// HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

///// Interim Website

// @font-face{
//   font-family:"Modern Gothic";
//   src:url("../fonts/ModernGothicJK-Regular.woff2") format("woff2");
//   font-style: normal;
//   font-weight: normal;
// }
//
// @font-face{
//   font-family:"Modern Gothic";
//   src:url("../fonts/ModernGothicJK-Italic.woff2") format("woff2");
//   font-style: italic;
//   font-weight: normal;
// }

@font-face{
  font-family:"Modern Gothic Poster";
  src:url("../fonts/ModernGothicPoster-BoldPoster.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
}

@font-face{
  font-family:"Modern Gothic";
  src:url("../fonts/ModernGothicJK-Medium.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
}

@font-face{
  font-family:"Modern Gothic";
  src:url("../fonts/ModernGothicJK-MediumItalic.woff2") format("woff2");
  font-style: italic;
  font-weight: normal;
}

@mixin sm {
  @media (min-width: 0) and (max-width: 576px) {
     @content;
   }
}

body, html {
  font-size: 30px;
  font-family: "Modern Gothic", "Helvetica", "Helvetica Neue", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  @include sm {
    font-size: 21px;
  }
}

.hidden {
  display: none;
}

header{
  text-align: center;
  margin: 1rem;
  h1 {
    display: inline-block;
    @include sm {
      font-size: 1.5rem;
    }
    .jk {
      text-transform: uppercase;
      word-spacing: .4rem;
      margin-right: .5rem;
      @include sm {
        display: block;
        margin-right: 0;
        margin-bottom: .5rem;
      }
    }
  }
  .contact {
    text-transform: uppercase;
  }
  .hamburger, .close {
    width: .7rem;
    height: .7rem;
    display: inline-block;
    margin-left: .4rem;
    cursor: pointer;
    @include sm {
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: 1rem;
    }
  }
  .hamburger {
    &:hover {
      &:after, &:before {
        background: #000;
      }
    }
    &:after, &:before {
      content: '';
      width: .7rem;
      height: 3px;
      background: #CCCCCC;
      display: block;
      position: relative;
      @include sm {
        width: 1rem;
      }
    }
    &:before {
      top: .2rem;
    }
    &:after {
      top: .5rem;
    }
  }
  .close {
    &:hover {
      &:after, &:before {
        background: #000;
      }
    }
    &:after, &:before {
      content: '';
      width: .7rem;
      height: 3px;
      background: #CCCCCC;
      display: block;
      position: relative;
      @include sm {
        width: 1rem;
      }
    }
    &:before {
      top: .4rem;
      transform: rotate(45deg);
      @include sm {
        top: .6rem;
      }
    }
    &:after {
      top: .3rem;
      transform: rotate(-45deg);
      @include sm {
        top: .45rem;
      }
    }
  }
}

a {
  color: #CCCCCC;
  text-decoration: none;
  &:hover {
    color: black;
  }
}

.mobile {
  display: none;
  @include sm {
    display: inherit;
  }
}

.desktop {
  @include sm {
    display: none;
  }
}

section#main {
  margin: 1rem 2rem 4rem;
  @include sm {
    margin: 1rem 1rem 4rem;
  }
  h2 {
    font-family: "Modern Gothic Poster", "Helvetica", "Helvetica Neue", arial, sans-serif;
    font-size: 3rem;
    text-align: center;
    line-height: .9;
    margin: 4rem auto;
    @include sm {
      font-size: 3.5rem;
    }
  }
  p {
    margin-bottom: 1rem;
    line-height: 1.1;
  }
  .block-gallery {
    margin: 0 auto;
    width: 100%;
    height: 67vh;
    overflow-x: hidden;
    @include sm {
      margin: 1rem 0 1rem -1rem;
      width: calc(100% + 2rem);
    }
    .swiper-slide {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      cursor: pointer;
      &:active{
        cursor: grab;
      }
      &.left {
        img {
          left: 0;
          // object-position: right;
        }
      }
      &.center {
        img {
          left: 50%;
          transform: translate(-50%, 0);
          // object-position: center;
        }
      }
      &.right {
        img {
          right: 0;
          // object-position: left;
        }
      }
      figure {
        display: flex;
        max-height: 100%;
        @include sm {
          height: 100%;
          width: 100%;
          position: relative;
        }
        img {
          object-fit: contain;
          width: auto;
          max-width: 100%;
          max-height: 100%;
          padding: 3rem;
          @include sm {
            object-fit: cover;
            padding: 0;
            position: absolute;
            height: 100%;
          }
          &.landscape {
            @include sm {
              width: 300vw;
              max-width: 300vw;
            }
          }
        }
      }
    }
  }
}

footer {
  margin: 1rem 2rem;
  @include sm {
    font-size: 1.5rem;
    line-height: 1.2;
    margin: 1rem;
  }
}
